import React from 'react';
import "./preview.css";
import dashboard from "../../assets/dashboard.png";
import charts from "../../assets/charts.png";
const Preview = () => {
    return (
        <div className='preview section__padding'>
            <div className='preview_top'>
                <div className='preview_top-img'>
                    <img src={dashboard} alt="dashboard" />
                </div>
                <div className='preview_top-text'>
                    <h1 className='gradient__text'>Predictions</h1>
                    <p>
                        Explore detailed information on any match, including height, weight, reach, and more. Our
                        Predictions Dashboard offers percentage-based forecasts for each fight up to 4 weeks in
                        advance, empowering you to make informed decisions. Navigate our display board for numeric
                        metrics, a "Missing Data" alert (Learn More), and, of course, the win prediction/percentage.
                    </p>
                </div>

            </div>


            <div className='preview_bottom'>
                <div className='preview_bottom-text'>
                    <h1 className='gradient__text'>Visualizations</h1>
                    <p>
                        Discover the unique attributes of each fighter through comprehensive statistical
                        analysis. Utilize visually compelling comparisons that highlight strengths and weaknesses.
                        Examples include average fight time, takedown average, submission average, takedown defense,
                        takedown accuracy, striking accuracy, defense, landed per minute, absorbed per minute,
                        output by body part, and many more with more to come!
                    </p>
                </div>
                <div className='preview_bottom-img'>

                    <img src={charts} alt="charts" />
                </div>

            </div>

        </div>
    )
}

export default Preview;
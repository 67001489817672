import React from 'react'
import { GiPunch } from 'react-icons/gi';
import { Tooltip, PolarAngleAxis, PolarGrid, PolarRadiusAxis, RadarChart, ResponsiveContainer, Radar, Legend } from 'recharts';
import { HeadIcon, BodyIcon, LegIcon } from '../../chart_assets/';
import "../chart_styling.css";
import "../../chart_tooltip_styling.css";

const CustomeToolTip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
        return (
            <div className='custom-tooltip' style={{ boxShadow: "0px 0px 9px white" }}>


                <div className='multi-stat_header'>
                    <div className='custom-tooltip_icon'>
                        {payload[0].payload.icon}
                    </div>
                    <h3 >



                        {payload[0].payload.stat}
                    </h3>

                </div>

                <div className='custom-toolip_multi-div'>
                    <div className='custom-toolip_multi-unit'>
                        <b style={{ color: payload[0].payload.rColorIndex }}>{payload[0].name}</b>
                        <p > <b>{payload[0].value}</b> strikes</p>

                    </div>


                    <div className='custom-toolip_multi-unit'>
                        <b style={{ color: payload[0].payload.bColorIndex }}>{payload[1].name}</b>
                        <p > <b>{payload[1].value}</b> strikes</p>
                    </div>
                </div>





            </div>
        );
    }
    return null;
};


const StrikesByPart = (props) => {
    const data = [

        {

            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8",
            bodyPart: "Head",
            icon: <HeadIcon />,

            red: props.data[props.index].r_career_significant_strikes_head_landed,
            blue: props.data[props.index].b_career_significant_strikes_head_landed,
            redFighter: props.data[props.index].r_name,
            blueFighter: props.data[props.index].b_name
        },
        {
            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8",
            bodyPart: "Body",
            icon: <BodyIcon />,
            red: props.data[props.index].r_career_significant_strikes_body_landed,
            blue: props.data[props.index].b_career_significant_strikes_body_landed,
            redFighter: props.data[props.index].r_name,
            blueFighter: props.data[props.index].b_name

        },
        {
            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8",
            bodyPart: "Legs",
            icon: <LegIcon />,

            red: props.data[props.index].r_career_significant_strikes_leg_landed,
            blue: props.data[props.index].b_career_significant_strikes_leg_landed,
            redFighter: props.data[props.index].r_name,
            blueFighter: props.data[props.index].b_name

        }
    ]

    return (

        <ResponsiveContainer width={"99%"} height={"100%"} >
            <RadarChart
                {...{
                    overflow: 'visible'
                }}

                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                cx="50%" cy="60%" data={data}  >
                <PolarGrid />
                <PolarAngleAxis dataKey={"bodyPart"} label={"Strikes By Body Part"} tick={CustomLabel} tickCount={3} >


                </PolarAngleAxis>
                <PolarRadiusAxis angle={30} domain={[0, "auto"]} />
                <Radar name={data[0].redFighter} dataKey="red" stroke="#FF5858" strokeWidth={3} dot fill="#FF5858" fillOpacity={0.4}>

                </Radar>
                <Radar name={data[0].blueFighter} dataKey="blue" stroke="#4c9cf8" fill="#4c9cf8" dot fillOpacity={0.4} />
                <Legend content={<CustomLegend />} verticalAlign='top' />
                <Tooltip content={<CustomeToolTip />} />
            </RadarChart>
        </ResponsiveContainer>
    )
}

const CustomLegend = (props) => {
    const payload = props.payload;
    return (
        <g className='AD_legend'>
            {
                payload.map((entry, index) => (

                    <div key={`item-${index}`}>
                        <span style={{ color: entry.color, paddingRight: "4px", verticalAlign: "middle" }}>{<GiPunch />}</span>
                        <span style={{ color: entry.color }} >{entry.value}</span>
                    </div>

                ))

            }
        </g >

    )
};

const CustomLabel = (props) => {
    const { x, y, payload } = props;

    return (


        < g className='parts_icon'>
            <g>
                {payload.value === "Head" ? <HeadIcon x={x} y={y} /> : payload.value === "Body" ? <BodyIcon x={x} y={y} /> : <LegIcon x={x} y={y} />}
            </g>
        </g>






    );

};



export { StrikesByPart };
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { EventOne, EventTwo, EventThree, EventFour } from './Prediction_Tabs/index';


const Tab = styled.button`
    margin-top:0%;
    cursor: pointer;
    opacity: .5;
    border: 0;
    outline: 0;
    border-radius: 0;
    color: #FFF;
    

    ${({ active }) =>
        active &&
        `
       
        border-top:0%;
        border-left:0%;
        border-right:0%;
        background: #031B34;
        border-bottom: 4px;
        border-style: solid;
        font-weight:900;

        border-image: 
          linear-gradient(
            111.3deg, #FF5858 0%, #7FBBFF 100%
          ) 0 0 100% 0;
    
        opacity: 1;
  `}
  
  `;


const ButtonGroup = styled.div`
  display: flex;
 

  
`;

function PredictionTabsOutput(props) {

    useEffect(() => {
        console.log(props.events);
        for (var stat in props.events) {
            nullChecker(props.events[stat]);

        }
    })

    const nullChecker = (item) => {
        let red_count = 0;
        let blue_count = 0;
        for (var i in item) {
            for (const [key, value] of Object.entries(item[i])) {
                if (key.startsWith("r_") & (value === null | value === "")) {
                    red_count += 1;
                }
                if (key.startsWith("b_") & (value === null || value === "")) {
                    blue_count += 1;
                }
            }
            item[i]["r_null_count"] = red_count;
            item[i]["b_null_count"] = blue_count;
        }

    }

    console.log(props.fighterHistory)
    const types = [<EventOne data={props.events[0]} fighterHistory={props.fighterHistory[0]} />, <EventTwo data={props.events[1]} fighterHistory={props.fighterHistory[1]} />, <EventThree data={props.events[2]} fighterHistory={props.fighterHistory[2]} />, <EventFour data={props.events[3]} fighterHistory={props.fighterHistory[3]} />];
    const [active, setActive] = useState(types[0]);
    const typeNames = [props.eventNames[0], props.eventNames[1], props.eventNames[2], props.eventNames[3]];

    const setTab = (e) => {
        const value = e;
        setActive(value);
    };



    return (

        <>
            <ButtonGroup className='tab_button-group'>
                <Tab
                    className='tab_one'
                    key={typeNames[0]}
                    active={active.type.name === types[0].type.name}
                    onClick={() => setTab(types[0])}
                >

                    {typeNames[0]}
                </Tab>

                <Tab
                    className='tab_two'
                    key={typeNames[1]}
                    active={active.type.name === types[1].type.name}

                    onClick={() => setTab(types[1])}
                >
                    {typeNames[1]}
                </Tab>

                <Tab
                    className='tab_three'

                    key={typeNames[2]}
                    active={active.type.name === types[2].type.name}
                    onClick={() => setTab(types[2])}
                >
                    {typeNames[2]}
                </Tab>
                <Tab
                    className='tab_four'

                    key={typeNames[3]}
                    active={active.type.name === types[3].type.name}
                    onClick={() => setTab(types[3])}
                >
                    {typeNames[3]}
                </Tab>
            </ButtonGroup>
            <div className='iq__predictions-active_tab'>
                {active}
            </div>


        </>

    );

}
export default PredictionTabsOutput;
import { Route, Routes, useNavigate } from "react-router-dom";
import App from "./App";
import './app_router.css';
import React, { useEffect, useState, useRef } from 'react';
import { Navbar, Register } from "./components/";
import Login from "./components/userControl/login/Login";
import Output from "./pages/predictions/Predictions";
import AuthService from "./services/auth.service";
import AccountDashboard from "./pages/account/Account_Dashboard";
import {
  PayPage,
  TermsOfService,
  PrivacyPolicy,
  BillingChange,

} from "./pages";
import { Footer } from './containers';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from "./components/loading/LoadingSreen";
import authService from "./services/auth.service";


const AppRouter = () => {
  const navigation = useNavigate();
  const [success, setSuccess] = useState(false);
  const [isShowRegister, setIsShowRegister] = useState(true);
  const [isShowLogin, setIsShowLogin] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [getStartedEmail, setGetStartedEmail] = useState("");
  const [frontCheck, setFrontCheck] = useState(false);

  const toastId = useRef(null);
  const [customerLoaded, setCustomerLoaded] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState({
    id: null,
    lastFour: null,
    status: "Inactive",
    chargedThroughDate: null,
    plan: "N/A",
    actions: null,
    isPaused: null,
    isLoading: false

  });

  const showToastMessage = (message, messageType = "default") => {
    if (!toast.isActive(toastId.current)) {
      switch (messageType) {
        case "default":
          toastId.current = toast(message, {
            position: toast.POSITION.TOP_CENTER,
          });
          break;

        case "success":
          toastId.current = toast.success(message, {
            position: toast.POSITION.TOP_CENTER,
          });
          break;
        case "error":
          toastId.current = toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
          });
          break;
        case "info":
          toastId.current = toast.info(message, {
            position: toast.POSITION.TOP_CENTER,
          });
          break;



      }
    }
  };

  useEffect(() => {

    const user = AuthService.getCurrentUser("user");

    if (user) {
      setCurrentUser(user);
      setLoggedIn(true);
    }

  }, [success]);


  useEffect(() => {

    if (currentUser) {
      AuthService.verifyUserToken(currentUser.accessToken)
        .then((e) => {
          showToastMessage(e.data.message);
        }).catch(err => {
          if (err.response.status === 401) {
            authService.logout();
            navigation("/");
            navigation(0);

          }
        });




      setFrontCheck(true);
      if (currentUser.roles.includes("ROLE_LIFETIME")) {
        setSubscriptionInfo({
          id: currentUser.subscriptionId,
          lastFour: "LIFETIME",
          status: "LIFETIME",
          chargedThroughDate: "LIFETIME",
          plan: "LIFETIME",
          isPaused: false,
          isLoading: false
        });
        setFrontCheck(false);
        setCustomerLoaded(true);

      } else if (currentUser.customerId !== null) {
        AuthService.getCustomerInfo(currentUser, frontCheck)
          .then(e => {



            setSubscriptionInfo({
              id: currentUser.subscriptionId,
              lastFour: e.lastFour,
              status: e.status,
              chargedThroughDate: e.nextCharge,
              plan: e.plan,
              isPaused: e.cancel_at_period_end,
              isLoading: false
            });


          }).catch((error) => {
            if (error.response.status === 404) {
              authService.logout();

            }
            if (error.response.status === 402) {

              setSubscriptionInfo({
                id: null,
                cardId: null,
                lastFour: null,
                status: "Inactive",
                chargedThroughDate: null,
                plan: "N/A",
                isPaused: null,
                isLoading: false
              });
            }
            if (error.response.status === 401) {

              setSubscriptionInfo({
                id: null,
                cardId: null,
                lastFour: null,
                status: "Inactive",
                chargedThroughDate: null,
                plan: "N/A",
                isPaused: null,
                isLoading: false
              });
              authService.degrade(currentUser);
              showToastMessage("Your subscription has run out. Please resubscribe for full access.");
            }

            if (error.response.status === 400) {

              setSubscriptionInfo({
                id: null,
                cardId: null,
                lastFour: null,
                status: "Inactive",
                chargedThroughDate: null,
                plan: "N/A",
                isPaused: null,
                isLoading: false
              });
              authService.degrade(currentUser);
              showToastMessage("Your subscription has run out. Please resubscribe for full access.");


            }
            setFrontCheck(false);
            setCustomerLoaded(true);
          });



        setFrontCheck(false);
        setCustomerLoaded(true);
      } else {
        setFrontCheck(false);

        setCustomerLoaded(true);

      }

    }
    else {
      setFrontCheck(false);
      setCustomerLoaded(true);
    }
  }, [loggedIn]);



  const handleGetStartedEmailInput = (e) => {
    setGetStartedEmail(e.target.value)
  };

  const handleRegisterClick = () => {
    setIsShowRegister((isShowRegister) => !isShowRegister);
    if (isShowLogin === false) {
      setIsShowLogin((isShowRegister) => !isShowRegister)
    }
  };


  const handleLoginClick = () => {
    setIsShowLogin((isShowLogin) => !isShowLogin);
    if (isShowRegister === false) {
      setIsShowRegister((isShowLogin) => !isShowLogin);
    }
  };

  if (!customerLoaded || frontCheck) {
    return (<LoadingScreen />)
  }

  return (
    <>
      <div className='gradient__bg'>


        <Navbar subscriptionInfo={subscriptionInfo}
          handleRegClick={handleRegisterClick}
          handleLoginClick={handleLoginClick}
          setLoggedIn={setLoggedIn}
          userLoggedIn={loggedIn} />

        <div className="register_container">

          <Login navigation={navigation}
            showToastMessage={showToastMessage}
            isShowLogin={isShowLogin}
            setSuccess={setSuccess}

            setIsShowLogin={setIsShowLogin}
            handleLoginClick={handleLoginClick} />


        </div>

        <div className="register_container">
          <Register
            navigation={navigation}
            showToastMessage={showToastMessage}
            isShowRegister={isShowRegister}
            setIsShowRegister={setIsShowRegister}
            handleRegClick={handleRegisterClick}
            quickStartEmail={getStartedEmail}
            handleLoginClick={handleLoginClick} />

        </div>
      </div>

      <Routes>
        <Route path="/" element={<App
          currentUser={currentUser}
          loggedIn={loggedIn}
          subscriptionInfo={subscriptionInfo}
          handleGetStartedEmailInput={handleGetStartedEmailInput}
          handleRegClick={handleRegisterClick}
          isShowRegister={isShowRegister}
        />} />


        <Route path="/predictions" element={<Output
          currentUser={currentUser}
          subscriptionInfo={subscriptionInfo}
          showToastMessage={showToastMessage}
        />} />

        <Route path="/account" element={<AccountDashboard
          currentUser={currentUser}
          subscriptionInfo={subscriptionInfo}
          setSubscriptionInfo={setSubscriptionInfo}
          showToastMessage={showToastMessage}
        />}
        />
        <Route path="/payment" element={<PayPage
          currentUser={currentUser}
          subscriptionInfo={subscriptionInfo}
          setSubscriptionInfo={setSubscriptionInfo}
          showToastMessage={showToastMessage}
        />}

        />
        <Route path="/billingchange" element={<BillingChange
          currentUser={currentUser}
          setSubscriptionInfo={setSubscriptionInfo}
          subscriptionInfo={subscriptionInfo}
          showToastMessage={showToastMessage}
        />}

        />


        <Route path="/tos" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />

      </Routes>
      <Footer handleRegClick={handleRegisterClick} userLoggedIn={loggedIn} subscriptionInfo={subscriptionInfo} />

    </>
  )

}

export default AppRouter;

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
    CartesianGrid,
    Legend,
    Scatter,
    LabelList,
    Tooltip,
    XAxis,
    YAxis,
    Label,
    ResponsiveContainer,
    ScatterChart,
    ComposedChart,
    Line,
    LineChart
} from 'recharts'
import "../chart_tooltip_styling.css"
import {GiPunch} from "react-icons/gi";

const CustomeToolTip = ({ active, payload }) => {
    console.log(active, payload)
    if (active && payload && payload.length) {

        return (

            <div className='custom-tooltip'>

                <div className='fighter_div'>
                    <p className='fighter'>Fighter:</p> {payload[0].payload.name}

                </div>
                <div className='event_date_div'>
                    <p className='event_date'>Fight Date:</p> {payload[0].payload.event_time}
                </div>


                <div className='tdl_div'>
                    <p className='tdl'>Significant Strikes Landed PM:</p> {payload[0].payload.career_significant_strikes_landed_pm}
                </div>



            </div>
        );
    }
    return null;
};

const CustomLegend = (props) => {
    const payload = props.payload;
    console.log(payload)
    return (
        <g className='AD_legend'>
            {
                payload.map((entry, index) => (

                    <div key={`item-${index}`} >
                        <span style={{ color: entry.payload.fill, paddingRight: "4px" }}>{entry.payload.icon}</span>
                        <span style={{ color: entry.payload.fill }} >{entry.value}</span>
                    </div>

                ))

            }
        </g>

    )
};

const StrikesLandedPm = (props) => {
    const [index, setIndex] = useState();
    const [redCD, setRedCD] = useState();
    const [redData, setRedData] = useState([]);
    const [blueData, setBlueData] = useState([]);

    useEffect(() => {
        setRedData(JSON.parse(props.fighterHistory[props.index].red_corner));
        setBlueData(JSON.parse(props.fighterHistory[props.index].blue_corner));
    }, [props.fighterHistory[props.index]]);
    console.log(props)

    return (

        <ScatterChart

            width={1300}
            height={600}
            margin={{
                top: 5,
                right: 20,
                left: 20,
                bottom: 5,

            }
            }
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                xAxisId="top"
                minTickGap={20}
                orientation='bottom'
                domain={["dataMin", "auto"]}
                dataKey="event_time"
                name='Fight Date'
                tick={{ fontSize: 14 }}
                tickFormatter={timeStr => moment(timeStr).format('YYYY-MM-DD')}
            />
            <XAxis

                xAxisId="bottom"
                minTickGap={20}
                dataKey="event_time"
                name='Fight Date'
                tick={{ fontSize: 14 }}
                tickFormatter={timeStr => moment(timeStr).format('YYYY-MM-DD')}

            />

            <YAxis
                yAxisId="left" />
            <YAxis
                yAxisId="right"
                orientation="right" />

            <Tooltip content={<CustomeToolTip />} cursor={{ strokeDasharray: '3 3' }} />
            <Legend content={<CustomLegend />} verticalAlign='top' />
            <Scatter
                data={blueData}
                icon={<GiPunch />}
                fill="#4c9cf8"
                line
                name={props.data[props.index].b_name}

                yAxisId="right"
                xAxisId="bottom"
                dataKey="career_significant_strikes_landed_pm"
            />
            <Scatter
                icon={<GiPunch />}
                line
                name={props.data[props.index].r_name}
                fill="#FF5858"
                data={redData}
                yAxisId="left"
                xAxisId="top"
                dataKey="career_significant_strikes_landed_pm"/>

        </ScatterChart>
    )
}

export { StrikesLandedPm };
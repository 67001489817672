import React, { useState } from "react";
import { DoughnutChart } from "../../charts/prediction_chart/Prediction_Chart";
import { RedStats, BlueStats } from "../../../pages/predictions/containers/FighterStats";
import "../prediction_tabs.css";
import {
    AvgFightTimeChart,
    StrikesByPart, StrikingAccuracyDefense,
    StrikingLandedAbsorbedPM,
    FighterAverages,
    TakedownAccuracyDefenseChart
} from "../../charts/current_career_stat_charts/";
import PredictionDisplay from "./Prediction_Display";
import NewDropdown from "../../DropdownMenu/NewDropdown";
import {OT_Takedowns_Landed, StrikesLandedPm} from "../../charts/timeline_career_stat_charts";



const EventOne = (props) => {
    const [index, setIndexes] = useState(0);
    const [pieIndex, setPieIndex] = useState();
    console.log(props)
    return (
        <div className="iq__charts-prediction-dashboard">
            <div className="iq__charts-prediction_header">

                <h2>{props.data[index].event_name}</h2>
                <p>{props.data[[index]].event_location}</p>
                <p>{props.data[[index]].event_time.replace(/\T.*/, '')} </p>
            </div>

            <NewDropdown data={props.data} index={index} setIndexes={setIndexes} />

            <div className='iq__predictions-container_holder'>

                <div className='iq__charts-prediction_chart'>




                    <DoughnutChart setPieIndex={setPieIndex} data={props.data} index={index} />
                </div>


                <div className='iq__predictions-dashboard_red'>
                    <RedStats data={props.data} index={index} />

                </div>
                <div className='iq__predictions-dashboard_blue' >
                    <BlueStats data={props.data} index={index} />
                </div>
                <div className="iq__prediction-display">
                    <PredictionDisplay pieData={props.data} pieIndex={pieIndex} fightIndex={index} />
                </div>
            </div>





            <h1 className="chart_section-header">Fighter Comparison</h1>


            <div className="iq__fighter-stat_comparison">


                <div className="multi_chart_div">

                    <div className="containers">

                        <div className="row">

                            <div className="row_chart">
                                <AvgFightTimeChart data={props.data} index={index}/>
                            </div>
                            <div className="row_chart">
                                <StrikesByPart data={props.data} index={index}/>
                            </div>

                        </div>

                        <div className="row">
                            <div className="row_chart">
                                <StrikingAccuracyDefense data={props.data} index={index}/>
                            </div>
                            <div className="row_chart">
                                <StrikingLandedAbsorbedPM data={props.data} index={index}/>
                            </div>

                        </div>
                        <div className="row">
                            <div className="row_chart">
                                <FighterAverages data={props.data} index={index}/>
                            </div>
                            <div className="row_chart">
                                <TakedownAccuracyDefenseChart data={props.data} index={index}/>
                            </div>

                        </div>

                    </div>

                    {/*
                        <h2>Fighter Averages</h2>

                            <StrikesLandedPm data={props.data} fighterHistory={props.fighterHistory}
                                                 index={index}/>
                    */}
                </div>

            </div>

        </div>
    );
}


export {EventOne};